import * as Globalize from "globalize/dist/globalize";

export default function enableJqueryGlobalize() {
  const originalMethods = {
    min: $.validator.methods.min,
    max: $.validator.methods.max,
    range: $.validator.methods.range
  };

  // Tell the validator that we want numbers parsed using Globalize
  $.validator.methods.number = function (value: string, element: string) {
    const val = Globalize.parseNumber(value);
    return this.optional(element) || ($.isNumeric(val));
  };

  // Tell the validator that we want dates parsed using Globalize
/*
  $.validator.methods.date = function (value: string, element: HTMLElement) {
    const val = Globalize.parseDate(value);
    return this.optional(element) || (val instanceof Date);
  };
*/

  // Tell the validator that we want numbers parsed using Globalize, 
  // then call into original implementation with parsed value
  $.validator.methods.min = function (value: string, element: string, param: any) {
    const val = Globalize.parseNumber(value);
    return originalMethods.min.call(this, val, element, param);
  };

  $.validator.methods.max = function (value: string, element: string, param: any) {
    const val = Globalize.parseNumber(value);
    return originalMethods.max.call(this, val, element, param);
  };

  $.validator.methods.range = function (value: string, element: string, param: any) {
    const val = Globalize.parseNumber(value);
    return originalMethods.range.call(this, val, element, param);
  };
}
