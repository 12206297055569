import * as Globalize from "globalize/dist/globalize";
import enableJqueryGlobalize from './jquery-validation-globalize';
import 'globalize/dist/globalize/number';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

import likelySubTags from 'cldr-data/supplemental/likelySubtags.json';
import deNumber from 'cldr-data/main/de/numbers.json';
import enNumber from 'cldr-data/main/en/numbers.json';
import esNumber from 'cldr-data/main/es/numbers.json';
import nlNumber from 'cldr-data/main/nl/numbers.json';

window.Globalize = Globalize;
Globalize.load(likelySubTags);
Globalize.load(nlNumber);
Globalize.load(enNumber);
Globalize.load(deNumber);
Globalize.load(esNumber);
enableJqueryGlobalize();
