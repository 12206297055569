import * as $ from "jquery";
import './validation'

declare global {
  interface Window {
    ifunds: { strings: any };
  }
}

$(function () {
  $(document).on("click", ".delete", function () {
    return confirm(window.ifunds.strings.areYouSureToDeleteThis);
  }).on("click", ".delete-attachment", function () {
    const attachment = $(this).closest(".attachment");
    attachment.addClass("marked-for-deletion");
    attachment.find("input[name=_deleteAttachment]").val(attachment.find("input[type=file]").attr("name"));
  }).on("click", ".row-navigation tbody tr", function () {
    const href = $(this).find("a").attr("href");
    if (href) {
      window.location.href = href;
    }
  }).on("change", "[data-auto-postback]", function () {
    $(this).closest("form").trigger('submit');
  })

  $("form").on("submit", function (e) {
    const $form = $(this);
    if ($form.data("submitted") === true) {
      console.log("Form already submitted.");
      e.preventDefault();
    } else {
      if ($form.valid()) {
        $form.data("submitted", true);
      }
    }
  });

  $(document).on("click", "[data-print]", function () {
    window.print();
  });
  
  $(document).on("click", ".btn-close", function() {
    $(this).closest(".card").hide();
  });

  $(document).on("change", ".document-attachment input[type=file]", function () {
    const file = $(this).get(0).files[0];
    if (file) {
      const $attachment = $(this).closest(".document-attachment");
      $attachment.find(".selected-document-file-name").text(file.name);
      $attachment.find(".document-id").val("");
    }
  })
  
  window.toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-bottom-center",
    preventDuplicates: false,
    onclick: null,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };
});

$.validator.unobtrusive.adapters.add('filesize', ['maxsize'], function (options: any) {
  options.rules['filesize'] = {maxsize: options.params.maxsize};
  if (options.message) {
    options.messages['filesize'] = options.message;
  }
});

$.validator.addMethod("filesize", function (value, element: HTMLInputElement, param) {
  if (value === "") {
    return true;
  }
  const maxsize = parseInt(param.maxsize);
  if (element.files && element.files[0] && element.files[0].size) {
    const filesize = element.files[0].size;
    return filesize <= maxsize;
  }
  return true;
});

$.validator.unobtrusive.adapters.addBool("iban");
$.validator.addMethod("iban", function (value, element) {
  return this.optional(element) || isValidIban(value);
});

function isValidIban(input: string) {
  const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, ''),
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  let digits;
  if (!code) {
    return false;
  }
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return String(letter.charCodeAt(0) - 55);
  });
  return mod97(digits) === 1;
}
 
function mod97(s: string) {
  let checksum : number = +s.slice(0, 2), fragment: string;
  for (let offset = 2; offset < s.length; offset += 7) {
    fragment = String(checksum) + s.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

